<template>
  <div>
    <kandidat-menu
      v-if="title === $t('IzmeniKandidata')"
      :kandidat="data"
      :active-item="activeItem"
    />
    <b-card
      no-body
    >
      <b-card-body>
        <b-row>
          <b-col
            md="6"
            xl="6"
            class="mb-1"
          >
            <b-form-group
              :label="$t('DosaoIzDrugeSkole')"
              label-for="dosaoIzDrugeSkole"
            >
              <b-form-checkbox
                id="dosaoIzDrugeSkole"
                v-model="data.dosao_iz_druge_skole"
                checked="false"
                name="check-button"
                switch
                inline
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            xl="6"
            class="mb-1"
          >
            <!-- basic -->
            <b-form-group
              :label="$t('IdBrojKandidata')"
              label-for="idkandidata"
            >
              <b-form-input
                id="idkandidata"
                v-model="idKandidata"
                :placeholder="$t('IdBrojKandidata')"
              />
              <small
                v-if="formErrors['idkandidata']"
                class="text-danger"
              >
                {{ $t(formErrors['idkandidata'][0]) }}
              </small>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row
          v-if="data.dosao_iz_druge_skole"
        >
          <b-col
            md="6"
            xl="6"
            class="mb-1"
          >

            <!-- basic -->
            <b-form-group
              :label="$t('DatumPocetkaTeorijskeObuke')"
              label-for="datum_pocetka_teorijske_obuke"
            >
              <b-form-datepicker
                id="datum_pocetka_teorijske_obuke"
                v-model="data.datum_pocetka_teorijske_obuke"
                :placeholder="$t('DatumPocetkaTeorijskeObuke')"
                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                :locale="this.$i18n.locale"
                :hide-header="hideHeader"
                today-button
                reset-button
                close-button
                show-decade-nav
                :label-today-button="$t('Danas')"
                :label-reset-button="$t('Ponisti')"
                :label-close-button="$t('Zatvori')"
                :label-prev-year="$t('PrethodnaGodina')"
                :label-prev-month="$t('PrethodniMesec')"
                :label-next-year="$t('SledecaGodina')"
                :label-next-month="$t('SledeciMesec')"
                :label-current-month="$t('TekuciMesec')"
                :label-prev-decade="$t('MinusDesetGodina')"
                :label-next-decade="$t('PlusDesetGodina')"
                label-help=""
                no-flip
              />
              <small
                v-if="formErrors['datum_pocetka_teorijske_obuke']"
                class="text-danger"
              >
                {{ $t(formErrors['datum_pocetka_teorijske_obuke'][0]) }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            xl="6"
            class="mb-1"
          >

            <!-- basic -->
            <b-form-group
              :label="$t('DatumPolaganjaTeorijskogIspita')"
              label-for="datum_polaganja_teorijskog_ispita"
            >
              <b-form-datepicker
                id="datum_polaganja_teorijskog_ispita"
                v-model="data.datum_polaganja_teorijskog_ispita"
                :placeholder="$t('DatumPolaganjaTeorijskogIspita')"
                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                :locale="this.$i18n.locale"
                :hide-header="hideHeader"
                today-button
                reset-button
                close-button
                show-decade-nav
                :label-today-button="$t('Danas')"
                :label-reset-button="$t('Ponisti')"
                :label-close-button="$t('Zatvori')"
                :label-prev-year="$t('PrethodnaGodina')"
                :label-prev-month="$t('PrethodniMesec')"
                :label-next-year="$t('SledecaGodina')"
                :label-next-month="$t('SledeciMesec')"
                :label-current-month="$t('TekuciMesec')"
                :label-prev-decade="$t('MinusDesetGodina')"
                :label-next-decade="$t('PlusDesetGodina')"
                label-help=""
                no-flip
              />
              <small
                v-if="formErrors['datum_polaganja_teorijskog_ispita']"
                class="text-danger"
              >
                {{ $t(formErrors['datum_polaganja_teorijskog_ispita'][0]) }}
              </small>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="6"
            xl="6"
            class="mb-1"
          >
            <!-- basic -->
            <b-form-group
              :label="$t('DatumUpisaURegistar')"
              label-for="datum_upisa_u_registar"
            >
              <b-form-datepicker
                id="datum_upisa_u_registar"
                v-model="datum_upisa_u_registar"
                :placeholder="$t('DatumUpisaURegistar')"
                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                :locale="this.$i18n.locale"
                :hide-header="hideHeader"
                today-button
                reset-button
                close-button
                show-decade-nav
                :label-today-button="$t('Danas')"
                :label-reset-button="$t('Ponisti')"
                :label-close-button="$t('Zatvori')"
                :label-prev-year="$t('PrethodnaGodina')"
                :label-prev-month="$t('PrethodniMesec')"
                :label-next-year="$t('SledecaGodina')"
                :label-next-month="$t('SledeciMesec')"
                :label-current-month="$t('TekuciMesec')"
                :label-prev-decade="$t('MinusDesetGodina')"
                :label-next-decade="$t('PlusDesetGodina')"
                label-help=""
                no-flip
              />
              <small
                v-if="formErrors['datum_upisa_u_registar']"
                class="text-danger"
              >
                {{ $t(formErrors["datum_upisa_u_registar"][0]) }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            xl="6"
          >
            <b-form-group
              v-if="typeof this.$route.params.id === 'undefined'"
              :label="$t('SledeciRedniBroj')"
              label-for="sledeciRedniBroj"
            >
              <b-form-input
                id="sledeciRedniBroj"
                v-model="sledeciRedniBroj"
                :placeholder="$t('SledeciRedniBroj')"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="6"
            xl="6"
            class="mb-1"
          >
            <!-- basic -->
            <b-form-group
              :label="$t('Ime')"
              label-for="ime"
            >
              <b-form-input
                id="ime"
                v-model="data.ime"
                :placeholder="$t('Ime')"
              />
              <small
                v-if="formErrors['ime']"
                class="text-danger"
              >
                {{ $t(formErrors['ime'][0]) }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            xl="6"
          >
            <b-form-group
              :label="$t('Prezime')"
              label-for="prezime"
            >
              <b-form-input
                id="prezime"
                v-model="data.prezime"
                :placeholder="$t('Prezime')"
              />
              <small
                v-if="formErrors['prezime']"
                class="text-danger"
              >
                {{ $t(formErrors['prezime'][0]) }}
              </small>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="6"
            xl="6"
          >
            <b-form-group
              :label="$t('JMBG')"
              label-for="jmbg"
            >
              <b-form-input
                id="jmbg"
                v-model="data.jmbg"
                :placeholder="$t('JMBG')"
              />
              <small
                v-if="formErrors['jmbg']"
                class="text-danger"
              >
                {{ $t(formErrors['jmbg'][0]) }}
              </small>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="6"
            xl="6"
          >
            <b-form-group
              :label="$t('MestoPrebivalista')"
              label-for="mesto"
            >
              <b-form-input
                id="mesto"
                v-model="data.mesto_prebivalista"
                :placeholder="$t('MestoPrebivalista')"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            xl="6"
          >
            <b-form-group
              :label="$t('Adresa')"
              label-for="adresa"
            >
              <b-form-input
                id="adresa"
                v-model="data.adresa"
                :placeholder="$t('Adresa')"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="6"
            xl="6"
          >
            <b-form-group
              :label="$t('Email')"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="data.email"
                type="email"
                :placeholder="$t('Email')"
              />
              <small
                v-if="formErrors['email']"
                class="text-danger"
              >
                {{ $t(formErrors['email'][0]) }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            xl="6"
          >
            <b-form-group
              :label="$t('Telefon')"
              label-for="telefon"
            >
              <b-form-input
                id="telefon"
                v-model="data.telefon"
                :placeholder="$t('Telefon')"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="6"
            xl="6"
          >
            <b-form-group
              :label="$t('Lozinka')"
              label-for="password"
            >
              <b-form-input
                id="password"
                v-model="data.password"
                type="password"
                autocomplete="off"
                :placeholder="$t('Lozinka')"
              />
              <small
                v-if="formErrors['password']"
                class="text-danger"
              >
                {{ $t(formErrors['password'][0]) }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            xl="6"
          >
            <b-form-group
              :label="$t('PonovoLozinka')"
              label-for="password_confirmation"
            >
              <b-form-input
                id="password_confirmation"
                v-model="data.password_confirmation"
                type="password"
                :placeholder="$t('PonovoLozinka')"
              />
              <small
                v-if="formErrors['password_confirmation']"
                class="text-danger"
              >
                {{ $t(formErrors['password_confirmation'][0]) }}
              </small>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="6"
            xl="6"
            class="mb-1"
          >
            <!-- basic -->
            <b-form-group
              :label="$t('DatumLekarskog')"
              label-for="datum_lekarskog"
            >
              <b-form-datepicker
                id="datum_lekarskog"
                v-model="datum_lekarskog"
                :placeholder="$t('DatumLekarskog')"
                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                :locale="this.$i18n.locale"
                :hide-header="hideHeader"
                today-button
                reset-button
                close-button
                show-decade-nav
                :label-today-button="$t('Danas')"
                :label-reset-button="$t('Ponisti')"
                :label-close-button="$t('Zatvori')"
                :label-prev-year="$t('PrethodnaGodina')"
                :label-prev-month="$t('PrethodniMesec')"
                :label-next-year="$t('SledecaGodina')"
                :label-next-month="$t('SledeciMesec')"
                :label-current-month="$t('TekuciMesec')"
                :label-prev-decade="$t('MinusDesetGodina')"
                :label-next-decade="$t('PlusDesetGodina')"
                label-help=""
                no-flip
              />
              <small
                v-if="formErrors['datum_lekarskog']"
                class="text-danger"
              >
                {{ $t(formErrors["datum_lekarskog"][0]) }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            xl="6"
            class="mb-1"
          >
            <b-form-group
              :label="$t('PotrebnaPomagala')"
              label-for="potrebna_pomagala"
            >
              <b-form-checkbox
                id="potrebna_pomagala"
                v-model="data.potrebna_pomagala"
                checked="false"
                name="check-button"
                switch
                inline
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="6"
            xl="6"
          >
            <b-form-group
              :label="$t('Kategorija')"
              label-for="kategorija"
            >
              <v-select
                v-model="kategorija"
                label="text"
                :options="kategorije"
                :placeholder="$t('IzaberiteKategoriju')"
              />
              <small
                v-if="formErrors['kategorija']"
                class="text-danger"
              >
                {{ $t(formErrors['kategorija'][0]) }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            xl="6"
          >
            <b-form-group
              :label="$t('Status')"
              label-for="status"
            >
              <v-select
                v-model="status"
                label="text"
                :options="statusi"
                :placeholder="$t('Status')"
              />
              <small
                v-if="formErrors['status']"
                class="text-danger"
              >
                {{ $t(formErrors['status'][0]) }}
              </small>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="6"
            xl="6"
          >
            <b-form-group
              :label="$t('Instruktori')"
              label-for="instruktori"
            >
              <v-select
                v-model="kandidat_instruktori"
                label="text"
                multiple
                :options="instruktori"
                :placeholder="$t('IzaberiteInstruktore')"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            xl="6"
          >
            <b-form-group
              :label="$t('Predavaci')"
              label-for="predavaci"
            >
              <v-select
                v-model="kandidat_predavaci"
                label="text"
                multiple
                :options="predavaci"
                :placeholder="$t('IzaberitePredavace')"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="6"
            xl="6"
            class="mb-1"
          >
            <b-form-group
              :label="$t('MogucnostTestiranja')"
              label-for="mogucnost_testiranja"
            >
              <b-form-checkbox
                id="mogucnost_testiranja"
                v-model="data.mogucnost_testiranja"
                checked="false"
                name="check-button"
                switch
                inline
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            xl="6"
            class="mb-1"
          >
            <b-form-group
              :label="$t('PrvaPomoc')"
              label-for="prva_pomoc"
            >
              <b-form-checkbox
                id="prva_pomoc"
                v-model="data.prva_pomoc"
                checked="false"
                name="check-button"
                switch
                inline
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
      <b-card-body
        :title="$t('KategorijeKojePoseduje')"
      >
        <b-row>
          <b-col
            md="6"
            xl="6"
            class="mb-1"
          >
            <div class="demo-inline-spacing">
              <b-form-checkbox
                v-for="kat in kategorije"
                :key="kat.value"
                v-model="kategorijeKojePoseduje"
                :value="kat.value"
                checked="false"
                name="kategorijeKojePoseduje"
                class="custom-control-success"
                inline
              >
                {{ kat.text }}
              </b-form-checkbox>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
      <b-card-body>
        <b-row>
          <b-col
            md="12"
            xl="12"
          >
            <b-form-group
              :label="$t('Napomena')"
              label-for="napomena"
            >
              <b-form-textarea
                id="napomena"
                v-model="data.napomena"
                :placeholder="$t('Napomena')"
                rows="3"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
      <b-card-body>
        <b-row>
          <b-col
            md="4"
            xl="4"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              @click="IzmeniKandidata"
            >
              {{ $t("Snimi") }}
            </b-button>
            <router-link :to="{ name: 'autoskola-kandidati' }">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
              >
                {{ $t("Odustani") }}
              </b-button>
            </router-link>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BFormInput, BRow, BCol, BFormGroup, BCard, BCardBody, BFormDatepicker, BButton, BFormCheckbox, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import KandidatMenu from './KandidatMenu.vue'

export default {
  components: {
    'kandidat-menu': KandidatMenu,
    BFormCheckbox,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BFormDatepicker,
    BButton,
    vSelect,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      zaposleni: [],
      predavaci: [],
      instruktori: [],
      data: {},
      hideHeader: true,
      kategorije: [],
      kategorijeKojePoseduje: [],
      statusi: [
        { value: 'aktivan', text: this.$i18n.t('Aktivan') },
        { value: 'neaktivan', text: this.$i18n.t('Neaktivan') },
      ],
      datum_upisa_u_registar: '',
      datum_lekarskog: '',
      formErrors: [],
      activeItem: 'osnovni_podaci',
      kandidat_instruktori: [],
      kandidat_predavaci: [],
      title: '',
      kategorija: [],
      status: { value: 'aktivan', text: this.$i18n.t('Aktivan') },
      sledeciRedniBroj: '',
      kod_pr_lica: '',
      idKandidata: '',
    }
  },
  computed: {
    kreiranIdKanidata() {
      if (typeof this.$route.params.id !== 'undefined' && this.$route.params.id !== 'kreiraj') {
        return this.data.idkandidata
      }
      return this.kod_pr_lica + (this.datum_upisa_u_registar !== '' ? moment(this.datum_upisa_u_registar, 'YYYY-MM-DD').format('YY') : '?') + (Object.values(this.kategorija).length ? this.kategorije.filter(e => e.value === this.kategorija.value)[0].kod_registar : '?') + this.sledeciRedniBroj
    },
  },
  watch: {
    datum_upisa_u_registar: {
      handler(val) {
        this.$http.post('/autoskole-administratori/poslednji-registar', { datum: val }).then(response => {
          this.sledeciRedniBroj = response.data.poslednjiRegistar
        })
      },
      deep: true,
    },
    kreiranIdKanidata: {
      handler(val) {
        this.idKandidata = val
      },
    },
  },
  mounted() {
    this.pokupiListu()
    if (typeof this.$route.params.id !== 'undefined' && this.$route.params.id !== 'kreiraj') {
      this.title = this.$i18n.t('IzmeniKandidata')
      this.$http.get(`/autoskole-administratori/kandidati/${this.$route.params.id}`).then(response => {
        this.data = response.data.kandidat
        this.datum_upisa_u_registar = response.data.kandidat.datum_upisa_u_registar
        this.datum_lekarskog = response.data.kandidat.datum_lekarskog
        this.data.kandidat_instruktori = []
        this.data.kandidat_predavaci = []
        this.data.password = ''
        const selektovanaKategorija = this.kategorije.filter(e => e.value === response.data.kategorija)[0]
        this.kategorija = selektovanaKategorija
        const selektovaniStatus = this.statusi.filter(e => e.value === response.data.kandidat.status)[0]
        this.status = selektovaniStatus
        this.kategorijeKojePoseduje = response.data.kategorijeKojePoseduje
        Object.entries(response.data.instruktori).forEach(value => {
          this.kandidat_instruktori.push({ value: value[1].instruktor_id, text: `${value[1].ime_prezime}` })
        })
        Object.entries(response.data.predavaci).forEach(value => {
          this.kandidat_predavaci.push({ value: value[1].predavac_id, text: `${value[1].ime_prezime}` })
        })
      })
    } else {
      this.title = this.$i18n.t('DodajKandidata')
    }
  },
  methods: {
    pokupiListu() {
      this.$http.get('/autoskole-administratori/zaposleni').then(response => {
        Object.entries(response.data).forEach(value => {
          this.zaposleni.push({ value: value[1].id, text: `${value[1].ime} ${value[1].prezime}` })
          if (value[1].da_li_je_instruktor === 'Da') {
            this.instruktori.push({ value: value[1].id, text: `${value[1].ime} ${value[1].prezime}` })
          }
          if (value[1].da_li_je_predavac === 'Da') {
            this.predavaci.push({ value: value[1].id, text: `${value[1].ime} ${value[1].prezime}` })
          }
        })
      })
      this.$http.get('/autoskole-administratori/kategorije').then(response => {
        Object.entries(response.data).forEach(value => {
          if (value[1].tip === 'osnovna') {
            this.kategorije.push({ value: value[1].id, text: `${value[1].naziv}`, kod_registar: `${value[1].kod_registar}` })
          }
        })
      })
      this.$http.get('/autoskole-administratori/autoskola').then(response => {
        this.kod_pr_lica = response.data.kod_pr_lica
      })
    },
    IzmeniKandidata() {
      const kandidatData = { ...this.data }
      kandidatData.kategorijeKojePoseduje = this.kategorijeKojePoseduje
      kandidatData.datum_upisa_u_registar = this.datum_upisa_u_registar
      kandidatData.datum_lekarskog = this.datum_lekarskog
      kandidatData.idkandidata = this.idKandidata
      const dataInstruktori = []
      const dataPredavaci = []
      if (typeof this.kandidat_instruktori !== 'undefined') {
        Object.entries(this.kandidat_instruktori).forEach(value => {
          dataInstruktori.push(value[1].value)
        })
      }
      if (typeof this.kandidat_predavaci !== 'undefined') {
        Object.entries(this.kandidat_predavaci).forEach(value => {
          dataPredavaci.push(value[1].value)
        })
      }
      kandidatData.instruktori = dataInstruktori
      kandidatData.predavaci = dataPredavaci
      kandidatData.status = this.status !== null ? this.status.value : ''
      kandidatData.kategorija = this.kategorija !== null ? this.kategorija.value : ''

      if (typeof this.$route.params.id !== 'undefined' && this.$route.params.id !== 'kreiraj') {
        this.$http.put(`/autoskole-administratori/kandidati/${kandidatData.id}`, kandidatData)
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$i18n.t('PodaciSuSnimljeni'),
                icon: 'EditIcon',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'autoskola-kandidati' })
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.formErrors = error.response.data.errors
            }
          })
      } else {
        kandidatData.sledeciRedniBroj = this.sledeciRedniBroj
        this.$http.post('/autoskole-administratori/kandidati', kandidatData)
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$i18n.t('PodaciSuSnimljeni'),
                icon: 'EditIcon',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'autoskola-kandidati' })
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.formErrors = error.response.data.errors
            }
          })
      }
    },
  },
}
</script>
